@tailwind base;


@layer base {
	@font-face {
		font-family: Dazzed;
		font-weight: 400;
		src: url('assets/fonts/dazzed/Dazzed-Regular.woff2') format("woff2");
	}

	@font-face {
		font-family: Dazzed;
		font-weight: 700;
		src: url('assets/fonts/dazzed/Dazzed-Bold.woff2') format("woff2");
	}

	@font-face {
		font-family: Dazzed;
		font-weight: 400;
		font-style: italic;
		src: url('assets/fonts/dazzed/Dazzed-RegularItalic.woff2') format("woff2");
	}

	@font-face {
		font-family: Dazzed;
		font-weight: 700;
		font-style: italic;
		src: url('assets/fonts/dazzed/Dazzed-BoldItalic.woff2') format("woff2");
	}

	@font-face {
		font-family: VictorSerif;
		font-weight: 300;
		src: url('assets/fonts/victorserif/VictorSerif-Light.woff2') format("woff2");
	}

	@font-face {
		font-family: VictorSerif;
		font-weight: 500;
		src: url('assets/fonts/victorserif/VictorSerif-Semibold.woff2') format("woff2");
	}

	body {
		@apply font-sans text-black ;
	}

	p {
		@apply text-black text-sm
	}

	th {
		@apply px-6 py-3 text-xs font-normal tracking-wider text-left text-gray-500 uppercase
	}

	a.link {
		@apply font-medium text-black border-b border-cobalt-500 hover:text-cobalt-400 hover:border-cobalt-400 transition-colors duration-150
	}

	.main-content {
		min-height: calc(100% - theme('height.32'));
	}

	.audio-player-active {
		@media (max-width: 1024px) {
			padding-bottom: 124px;
		}
	}

	wave {
		@media (max-width: 1024px) {
			height: 32px !important;
		}
	}
}

@tailwind components;
@tailwind utilities;


:root {
	--toastify-color-light: #fff;
	--toastify-color-dark: #121212;
	--toastify-color-info:  theme('colors.blue.50');
	--toastify-color-success: theme('colors.green.50');
	--toastify-color-warning: theme('colors.yellow.50');
	--toastify-color-error:  theme('colors.red.50');
	--toastify-color-transparent: rgba(255, 255, 255, 0.7);

	--toastify-icon-color-info: var(--toastify-color-info);
	--toastify-icon-color-success: var(--toastify-color-success);
	--toastify-icon-color-warning: var(--toastify-color-warning);
	--toastify-icon-color-error: var(--toastify-color-error);

	--toastify-toast-width: 320px;
	--toastify-toast-background: #fff;
	--toastify-toast-min-height: 64px;
	--toastify-toast-max-height: 800px;
	/*--toastify-font-family: @apply font-sans;*/

	--toastify-text-color-info: theme('colors.blue.700');
	--toastify-text-color-success: theme('colors.green.800');
	--toastify-text-color-warning: theme('colors.yellow.700');
	--toastify-text-color-error: theme('colors.red.700');

	--toastify-color-progress-info: var(--toastify-text-color-info);
	--toastify-color-progress-success: var(--toastify-text-color-success);
	--toastify-color-progress-warning: var(--toastify-color-warning);
	--toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info {
	background: theme('colors.blue.400');
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success {
	background: theme('colors.green.400');
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning {
	background: theme('colors.yellow.400');
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
	background: theme('colors.red.400');
}